@import '../node_modules/pivotal-ui/css/colors/colors';
@import '../node_modules/pivotal-ui/css/alignment/alignment';
@import '../node_modules/pivotal-ui/css/border/border';
@import '../node_modules/pivotal-ui/css/buttons/buttons';
@import '../node_modules/pivotal-ui/css/flex-grids/flex_grids';
@import '../node_modules/pivotal-ui/css/variables-and-mixins/pui-variables.scss';
@import '../node_modules/pivotal-ui/css/whitespace/whitespace';
@import '../node_modules/pivotal-ui/css/vertical-alignment/vertical_alignment';
@import '../node_modules/pivotal-ui/css/typography/typography';
@use "sass:math";
@use "sass:map";

$widths: 32 48 72 82 102 128 144 208;
$page-width: 990px;
$lighter-gray: rgb(248, 248, 248);
$background-yellow: rgb(255, 238, 179);
$strong-yellow: rgb(255, 191, 0);
$font-color: #253640;
$lighter-green: #00B5A3;
$color-red: #d93025;

@each $width in $widths {
  .width-#{$width} {
    width: #{$width}px;
  }
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("./fonts/SourceSansPro-BoldItalic.ttf") format("ttf");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("./fonts/SourceSansPro-SemiBoldItalic.ttf") format("ttf");
}

.release-dropdown.error {
  outline: $color-red solid 2px;
}

.release-dropdown.warning {
  outline: $decorative-yellow solid 2px;
}

.release-dropdown.info {
  outline: $accent-blue solid 2px;
}

a.release-link {
  text-decoration: underline;
}

.fill-decorative-yellow {
  fill: $decorative-yellow;
}

.no-margin {
  margin: 0
}

.page-width {
  min-width: #{$page-width};
}

.container {
  min-width: #{$page-width};
  margin-left: auto;
  margin-right: auto;
}

.docs-paragraph {
  padding-bottom: 10px;
}

.height-32 {
  height: 32px;
}

.height-auto {
  height: auto;
}

.flex-1 {
  flex: 1;
}

$max-num-cols: 24;

$col-sizes: ("col-0-5": 0.5, "col-3-5": 3.5, "col-6-5": 6.5);
@each $name, $size in $col-sizes {
  .#{$name} {
    $current_size: percentage($size/$max-num-cols);
    -ms-flex-preferred-size: #{$current_size};
    flex-basis: #{$current_size};
    max-width: #{$current_size};
  }
}

.header-button {
  border: 0px;
  padding: 0px;
  color: $white;
  align-self: center;
  text-transform: initial;
  font-weight: unset;
  font-size: 16px;
}

.pui-btn.pui-btn--default.pui-btn--flat.header-button,
.pui-btn.pui-btn--default.pui-btn--flat.header-button:focus,
.pui-btn.pui-btn--default.pui-btn--flat.header-button:hover {
  @extend .header-button
}

a.header-button:hover {
  color: $white;
}

.align-self-center {
  align-self: center;
}

.resize-vertical {
  resize: vertical;
}

.docs-h1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.loading-spinner {
  .overlay {
    @extend .bg-gray;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 100;
  }

  .spinner-bg {
    @extend .txt-c;
    padding-top: 200px;
    padding-left: 33.3%;
    padding-right: 33.3%;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 101;

    .box {
      @extend .pvxxl;
      @extend .bg-white;
      @extend .border;
      @extend .border-rounded;
      max-width: 425px;
      margin: auto;

      .icon {
        justify-content: center;
        font-size: 16px;
        position: inherit;
        padding-top: 0px;
      }
    }
  }
}

.product-row-with-banner {
  border: #1A98FF 1px solid !important;

  .banner {
    .banner-text {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 14px;
      line-height: 18px;

      .icon-col {
        font-size: 25px;
        padding-right: 0;
      }

      .message {
        padding-left: 0;
        padding-right: 20px;
      }
    }
  }
}

.product-row-with-info {
  @extend .product-row-with-banner;
  border-color: #1A98FF !important;

  .banner {
    background-color: #C2E7FF;

    .icon-col {
      fill: #0070EC;
    }
  }
}

.product-row-with-error {
  @extend .product-row-with-banner;
  border-color: $color-red !important;

  .banner {
    background-color: #fce8e6;

    .icon-col {
      fill: $color-red;
    }
  }
}

.product-row-with-warning {
  @extend .product-row-with-banner;
  border-color: $strong-yellow !important;

  .banner {
    background-color: $background-yellow;

    .icon-col {
      fill: $strong-yellow;
    }
  }
}

.app-body {
  @extend .mal;
  justify-content: left;
  min-height: 80vh;
  z-index: 0;
  width: #{$page-width};
}

.plan {
  h2 a {
    text-decoration: none;
    color: $font-color;
    cursor: default;
  }

  .plan-overview {
    .inner {
      overflow-x: auto;
    }

    .fixed {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 1;
      width: 200px;
    }

    td:nth-child(even), th:nth-child(even) {
      background-color: $lighter-gray;
    }

    td:nth-child(odd), th:nth-child(odd) {
      background-color: $white;
    }

    tr {
      line-height: 125%;
    }

    thead tr th {
      padding-bottom: 4px;
      padding-top: 4px;
    }

    tbody tr th, tbody tr td {
      padding: 4px 8px 4px;
    }

    tbody tr:hover {
      td, th {
        background-color: $lighter-gray;
      }
    }

    th:nth-child(2), th:last-child {
      width: 112px;
    }

    td, th {
      width: 75px;
    }
  }

  .success {
    min-height: 100%;

    ul {
      @extend .mbn;
    }

    caption {
      font-size: 20px !important;
      line-height: 32px !important;
      font-weight: 400 !important;
    }

    #upgrade-plan-phases {
      table {
        td, th {
          padding: 4px 0px 0px;
        }
      }
    }
  }

  .step {
    .release-with-warning {
      .alert {
        @extend .ptm;
        display: flex;

        .icon {
          fill: $decorative-yellow
        }

        ul {
          list-style-type: disc;
          padding-left: 18px;
        }
      }
    }
  }
}

.dropdown-common-props {
  cursor: default;

  &:hover {
    color: #253640;
    text-decoration: none;
    background: none;
  }
}

#export-plan {
  button {
    @extend .pui-btn;
    @extend .pui-btn--primary;
  }
}

.dropdown {
  .dropdown-toggle:focus {
    z-index: 1;
    outline: -webkit-focus-ring-color auto 2px !important;
  }

  .dropdown-button {
    @supports (-moz-appearance:none) {
      font-size: 13px !important; // Changes the size of the font to fit the button only in Firefox
    }
  }

  .dropdown-menu ul {
    li.dropdown-heading {
      font-weight: 700;
      font-style: italic;
      line-height: 15px;
      @extend .dropdown-common-props
    }

    li.incompatible-release {
      color: gray;
      @extend .dropdown-common-props;

      &:hover {
        color: gray;
      }
    }
  }
}

.ops-manager-version-selector {
  .grid .col-5, .grid .col-3-5 {
    padding-right: 6px;
    align-self: flex-end;

    .out-of-support-opsman-selected {
      border-color: $lighter-green;
      border-width: 2px;
      height: 100%;
    }

    .prior-versions {
      height: 100%;
      align-items: flex-end;
    }

    .version-card.lts-version-card {
      height: 80px !important;
      padding-left: 0px;
      padding-right: 0px;


      .lts-banner {
        @extend .txt-c;
        z-index: -100;
        background-color: black;
        color: white;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .version-card {
      align-self: flex-end;
      border: $light-gray 1px solid;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      height: 56px;
      cursor: pointer;

      .grid.outer-card {
        &:focus {
          outline: 2px dashed $lighter-green;
          outline-offset: 4px;
        }
      }

      .grid {
        height: 100%;
        margin-right: 0px;


        .version {
          @extend .em-high;
          font-size: 32px;
          line-height: 20px;
          text-align: left;
          align-items: center;
          align-self: center;
        }

        .new-features {
          @extend .type-xs;
          @extend .em-high;
        }

        .eogs {
          @extend .em-high;
          @extend .type-xs;
          align-self: flex-end;
        }
      }

      &.selected {
        border-color: $lighter-green;
      }
    }

    .version-card-selected {
      @extend .version-card;
      border-color: $lighter-green;
      border-width: 2px;
    }

    .recommended-text {
      @extend .txt-c;
      @extend .type-xs;
      @extend .em-high;
      @extend .ptm;
      font-size: 14px !important;

      color: $lighter-green;
    }
  }
}
